export default [
    {
        name: 'Pre-Algebra',
        country: 'USA',
        id: 'a1799185-4d71-4ec5-bc1e-3fd20a7b5d01',
        common_name: 'pre-algebra',
        question_count: '3,293',
        cover_image: 'https://pa-prepbox.s3.amazonaws.com/public/assets/pdfs/books/pre-algebra.png',
        bookPDF:
        'https://pa-prepbox.s3.amazonaws.com/public/assets/pdfs/books/pre-algebra.pdf',
        available: true,
    },
    {
        name: 'Algebra 1',
        country: 'USA',
        id: '4fb5af9b-21ed-49bc-91bd-f1e1ef6fee23',
        common_name: 'algebra-1-common-core',
        question_count: '3,681',
        bookPDF_name: 'PrepBox_Workbook-US_Common_Core-Algebra_1',
        cover_image:
            'https://pa-prepbox.s3.amazonaws.com/public/assets/pdfs/books/PrepBox_Workbook-US_Common_Core-Algebra_1.svg',
        bookPDF:
            'https://pa-prepbox.s3.amazonaws.com/public/assets/pdfs/books/PrepBox_Workbook-US_Common_Core-Algebra_1.pdf',
        available: true,
    },
    {
        name: 'High School Algebra',
        country: 'CA',
        id: '8db9067c-c9f5-4dc5-a1b4-9f0ea16696ea',
        common_name: 'high-school-algebra-fundamentals',
        question_count: '3,403',
        bookPDF_name: 'PrepBox_Workbook-Ontario-High_School_Algebra',
        cover_image:
            'https://pa-prepbox.s3.amazonaws.com/public/assets/pdfs/books/PrepBox_Workbook-Ontario-High_School_Algebra.png',
        bookPDF:
            'https://pa-prepbox.s3.amazonaws.com/public/assets/pdfs/books/PrepBox_Workbook-Ontario-High_School_Algebra.pdf',
        available: true,
    },
    {
        name: 'Advanced Functions',
        country: 'CA',
        id: 'f6e0aaba-aef2-43d5-8cd2-f2cfaf0780fd',
        common_name: 'advanced-functions-ontario-mhf4u',
        question_count: '1,606',
        bookPDF_name: 'PrepBox_Workbook-Ontario-Advanced_Functions',
        cover_image:
            'https://pa-prepbox.s3.amazonaws.com/public/assets/pdfs/books/PrepBox_Workbook-Ontario-Advanced_Functions.png',
        bookPDF:
            'https://pa-prepbox.s3.amazonaws.com/public/assets/pdfs/books/PrepBox_Workbook-Ontario-Advanced_Functions.pdf',
        available: true,
    },
    {
        name: '10 Math',
        country: 'CA',
        id: 'd0ec9c43-8af5-42e6-9d64-24a8c0ddf4ac',
        common_name: '10-principle-of-mathematics-mpm2d-ontario',
        question_count: '1,444',
        bookPDF_name: 'PrepBox_Workbook-Ontario-10_Math',
        cover_image:
            'https://pa-prepbox.s3.amazonaws.com/public/assets/pdfs/books/PrepBox_Workbook-Ontario-10_Math.png',
        bookPDF: 'https://pa-prepbox.s3.amazonaws.com/public/assets/pdfs/books/PrepBox_Workbook-Ontario-10_Math.pdf',
        available: true,
    },
    {
        name: '11 Math',
        country: 'CA',
        id: '1338c550-276b-4c39-b819-4956f3297b2d',
        common_name: 'functions-11-mcr3u',
        question_count: '1,566',
        bookPDF_name: 'PrepBox_Workbook-Ontario-11_Math',
        cover_image:
            'https://pa-prepbox.s3.amazonaws.com/public/assets/pdfs/books/PrepBox_Workbook-Ontario-11_Math.png',
        bookPDF: 'https://pa-prepbox.s3.amazonaws.com/public/assets/pdfs/books/PrepBox_Workbook-Ontario-11_Math.pdf',
        available: true,
    },
    {
        name: 'SAT Math Practice',
        country: 'USA',
        id: 'cd0cb9cd-9eaf-4783-8f80-da4690022cec',
        common_name: 'sat-practice-test-work-book',
        question_count: '302',
        bookPDF_name: 'PrepBox_Workbook-SAT_1-Math',
        cover_image: 'https://pa-prepbox.s3.amazonaws.com/public/assets/pdfs/books/PrepBox_Workbook-SAT_1-Math.svg',
        bookPDF: 'https://pa-prepbox.s3.amazonaws.com/public/assets/pdfs/books/PrepBox_Workbook-SAT_1-Math.pdf',
        available: true,
    },
    {
        name: 'Geometry',
        country: 'USA',
        id: '927e1dff-d5c5-4e40-856f-9a3257cc577d',
        common_name: 'geometry',
        question_count: '1,371',
        bookPDF_name: 'PrepBox_Workbook-US_Common_Core-Geometry',
        cover_image:
            'https://pa-prepbox.s3.amazonaws.com/public/assets/pdfs/books/PrepBox_Workbook-US_Common_Core-Geometry.png',
        bookPDF:
            'https://pa-prepbox.s3.amazonaws.com/public/assets/pdfs/books/PrepBox_Workbook-US_Common_Core-Geometry.pdf',
        available: false,
    },
    {
        name: '9 Math',
        country: 'CA',
        id: 'c1d61261-5031-4cb4-a8bb-713fecf635fe1',
        common_name: '10-math-ver-n',
        cover_image: 'https://pa-prepbox.s3.amazonaws.com/public/assets/images/books/ca_grade_9.svg',
        available: false,
    },

    {
        name: 'Algebra & Trigonometry',
        country: 'USA',
        id: '45ab-21ed-49bc-91bd-f1e1ef6fee23',
        common_name: 'algebra-and-trigonometry',
        cover_image: 'https://pa-prepbox.s3.amazonaws.com/public/assets/images/books/usa_algebra_and_trigonometry.svg',
        available: false,
    },
    {
        name: 'Algebra II',
        country: 'USA',
        id: '45af9b-21ed-49bc-91bd-f1e1ef6fee232',
        common_name: 'algebra-II',
        cover_image: 'https://pa-prepbox.s3.amazonaws.com/public/assets/images/books/usa_algebra_2.svg',
        available: false,
    },
    {
        name: 'Pre-Calculus',
        country: 'USA',
        id: '45af9b-21ed-49bc-91bd-f1e1ef6fee233',
        common_name: 'PreCalculus',
        cover_image: 'https://pa-prepbox.s3.amazonaws.com/public/assets/images/books/usa_pre_calculus.svg',
        available: false,
    },
];
